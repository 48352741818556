import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import WallpaperTile from "components/WallpaperTile"

import { useProducts } from "context/products"
import { useLocale } from "context/locale"

import fadeAnimation from "utils/fadeAnimation"

const AnimatedWallpaperTile = motion(WallpaperTile)

const Content = ({ pageData }) => {
  const { lang } = useLocale()
  const { products, productsEN } = useProducts()
  const filtered_products = lang === "en" ? productsEN : products

  return (
    <section className="outlet-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8 offset-xl-1">
            <motion.div
              className="outlet-content__description"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
              {...fadeAnimation}
            />
          </div>
        </div>

        <div className="row">
          {filtered_products
            ?.filter(item =>
              item.productCategories.nodes.some(node => node.slug === "outlet")
            )
            .map((item, index) => (
              <div className="col-lg-4 col-sm-6" key={index}>
                <AnimatedWallpaperTile item={item} {...fadeAnimation} />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Content
