/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"
import Bestsellers from "components/Bestsellers"

import { ContentSingle } from "page_components/outlet"

const Outlet = ({ pageContext, location }) => {
  const isBrowser = typeof window !== "undefined"
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  useEffect(() => {
    if (isBrowser) {
      window.gtag("event", "view_item", {
        currency: "PLN",
        value: pageContext?.data?.data.price,
        items: [
          {
            item_id: pageContext?.data?.data?.databaseId,
            item_name: pageContext?.data?.data?.name,
            price: pageContext?.data?.data?.price,
            quantity: 1,
          },
        ],
      })
    }
  }, [])

  return (
    <Layout location={location}>
      <Breadcrumbs
        parent={{ slug: "/outlet/", title: "Outlet" }}
        title={pageContext?.data?.data?.name}
        typeWider
      />
      <ContentSingle data={pageContext?.data?.data} />
      <Bestsellers />
      <BanerContact withMargin />
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const Head = ({ pageContext }) => (
  <Seo
    title={
      pageContext?.data?.data?.seo?.title
        ? pageContext?.data?.data?.seo?.title
        : pageContext?.data?.data?.name
    }
    description={
      pageContext?.data?.data?.seo?.metaDesc &&
      pageContext?.data?.data?.seo?.metaDesc
    }
  />
)

export default Outlet
