import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import WallpaperTile from "components/WallpaperTile"
import Button from "components/Button"

import { useProducts } from "context/products"
import { useLocale } from "context/locale"

import fadeAnimation from "utils/fadeAnimation"

const Bestsellers = ({ rmAction }) => {
  const { t, lang } = useLocale()
  const { products, productsEN } = useProducts()
  const [bestseller_count, setBestsellerCount] = useState(null)

  const filtered_products = lang === "en" ? productsEN : products

  useEffect(() => {
    setBestsellerCount(
      filtered_products?.filter(item =>
        item.productCategories.nodes.some(node => node.slug === "bestseller")
      )
    )
  }, [filtered_products])

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    bestseller_count?.length > 0 && (
      <section className="section-bestsellers">
        <div className="container-fluid">
          <SectionHeader
            title={t("Bestsellery")}
            content={t("- znajdź swoją inspirację")}
          />
        </div>
        <motion.div className="section-bestsellers__slider" {...fadeAnimation}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-11 offset-xxl-1">
                <Slider {...slider}>
                  {filtered_products
                    ?.filter(item =>
                      item.productCategories.nodes.some(
                        node => node.slug === "bestseller"
                      )
                    )
                    ?.map((item, index) => (
                      <div key={index}>
                        <WallpaperTile item={item} marginZero />
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </motion.div>
        {!rmAction && (
          <motion.div className="section-bestsellers__cta" {...fadeAnimation}>
            <div className="container-fluid">
              <Button to={t("/bestseller/")} className="button--bordered">
                {t("Zobacz więcej")}
              </Button>
            </div>
          </motion.div>
        )}
      </section>
    )
  )
}

export default Bestsellers
